<template>
  <div class="container-fluid p-0 mt-0" v-if="loading">

    <div class="row">
      <div class="col-lg-12">
        <div class="card mt-n4 mx-n4 mb-n5">
          <div class="bg-soft-success">
            <div class="card-body pb-4 mb-5">
              <div class="row">
                <div class="col-md">
                  <div class="row align-items-center">
                    <div class="col-md-auto">
                      <div class="avatar-md mb-md-0 mb-4">
                        <div class="avatar-title bg-white rounded-circle">
                          <img
                              src="https://static.wixstatic.com/media/db110e_21c6b16c1b524da3b794f2ed58cf9441~mv2.png/v1/fill/w_265,h_265,al_c,usm_0.66_1.00_0.01/Logistics%20Icon.png"
                              alt="" class="avatar-md">
                        </div>
                      </div>
                    </div>
                    <div class="col-md">
                      <h4 class="fw-semibold">
                        We are processing your order...
                      </h4>
                      <div class="hstack gap-3 flex-wrap">
                        <div class="text-muted" style="min-width: 115px">
                          <span class="align-middle d-inline">
                            <skeleton class="w-100"/>
                          </span>
                        </div>
                        <div class="vr"></div>
                        <div class="text-muted" style="min-width: 95px">
                          <skeleton class="w-100"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-9">
        <div class="card">
          <div class="card-body p-4 pt-2">

            <!-- TAB STARTS -->
            <ul class="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link active" data-bs-toggle="tab" href="#actual_cost_tab"
                   role="tab" aria-selected="false" tabindex="-1"> ACTUAL COST </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" data-bs-toggle="tab" href="#preliminary_cost_tab"
                   role="tab" aria-selected="false" tabindex="-1"> PRELIMINARY COST
                </a>
              </li>
            </ul>
            <!-- TAB ENDS -->

            <div class="tab-content text-muted">
              <div class="tab-pane active" id="actual_cost_tab" role="tabpanel">

                <div class="table-responsive table-card">
                  <table class="table table-striped">
                    <thead>
                    </thead>
                  </table>
                </div>

              </div>
              <div class="tab-pane" id="preliminary_cost_tab" role="tabpanel">
                <div class="table-responsive table-card">
                  <table class="table table-striped">
                    <thead class="table-light">
                    </thead>
                  </table>
                </div>
              </div>
            </div>
            <!-- TAB CONTENT ENDS -->
          </div>
        </div>
      </div>
      <div class="col-xxl-3">
        <div class="card">
          <div class="card-header"><h5 class="card-title mb-0">Order Details</h5></div>
          <div class="card-body">
            <div class="table-responsive table-card">
              <table class="table table-borderless align-middle mb-0">
                <tbody>
                <tr>
                  <td class="fw-medium">Lot number</td>
                  <td style="min-width: 125px">
                    <skeleton class="w-100"/>
                  </td>
                </tr>
                <tr>
                  <td class="fw-medium">Departure</td>
                  <td style="min-width: 125px">
                    <skeleton class="w-100"/>
                  </td>
                </tr>
                <tr>
                  <td class="fw-medium">Destination</td>
                  <td style="min-width: 125px">
                    <skeleton class="w-100"/>
                  </td>
                </tr>
                <tr>
                  <td class="fw-medium">Create Date</td>
                  <td style="min-width: 125px">
                    <skeleton class="w-100"/>
                  </td>
                </tr>
                <tr>
                  <td class="fw-medium">Manager</td>
                  <td style="min-width: 125px">
                    <skeleton class="w-100"/>
                  </td>
                </tr>
                <tr>
                  <td class="fw-medium">Client</td>
                  <td style="min-width: 125px">
                    <skeleton class="w-100"/>
                  </td>
                </tr>
                <tr>
                  <td class="fw-medium">Comment</td>
                  <td style="min-width: 125px">
                    <skeleton class="w-100"/>
                  </td>
                </tr>
                </tbody>
              </table>

              <button
                  class="btn btn-soft-success fw-medium w-100"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
              >See Actions
              </button>


            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


  <div class="container-fluid p-0 mt-0" v-else-if="!loading">

    <b-modal v-model="show_copy_wagon_numbers_modal" :title="`Empty Wagon Order - ${order.order_number}`" hide-footer
             centered>

      <div class="d-flex gap-2 align-items-center mb-3">
        <h5>Total wagons: </h5>
        <h5> {{ expenses.filter(w => w.wagon).length }}</h5>
      </div>

      <ul class="list-unstyled d-flex flex-column gap-2 mb-0">
        <li v-for="(wagon, i) in expenses.filter(w => w.wagon)" :key="`copy_wagon_${i}`" class="mb-0">
          {{ wagon.wagon.name }}
        </li>
      </ul>
    </b-modal>

    <div class="row">
      <div class="col-lg-12">
        <div class="card mt-n4 mx-n4 mb-n5">
          <div class="bg-soft-success">
            <div class="card-body pb-4 mb-5">
              <div class="row align-items-center">
                <div class="col-md">
                  <div class="row align-items-center">
                    <div class="col-md-auto">
                      <div class="avatar-md mb-md-0 mb-4">
                        <div class="avatar-title bg-white rounded-circle">
                          <img
                              src="https://static.wixstatic.com/media/db110e_21c6b16c1b524da3b794f2ed58cf9441~mv2.png/v1/fill/w_265,h_265,al_c,usm_0.66_1.00_0.01/Logistics%20Icon.png"
                              alt="" class="avatar-md">
                        </div>
                      </div>
                    </div>
                    <div class="col-md">
                      <h4 class="fw-semibold">
                        Order number - {{ order.order_number }}
                      </h4>
                      <div class="hstack gap-3 flex-wrap">
                        <div class="text-muted text-capitalize">
                          <i class="ri-building-line align-bottom me-1"></i>
                          {{ order.position.replace('_', ' ') }}
                        </div>
                        <div class="vr"></div>
                        <div class="text-muted text-capitalize">Order type :
                          {{ order.type }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md text-end" v-if="order.order_number">
                  <router-link :to="{name: 'orders_empty_wagon_update', params: {id: order.order_number}}">
                    <b-button variant="outline-dark" size="lg">
                      Edit Order
                      <i class="ri-pencil-fill align-bottom ms-1"></i>
                    </b-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-9">
        <div class="card">
          <div class="card-body p-4 pt-2">
            <!-- TAB STARTS -->
            <ul class="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link active" data-bs-toggle="tab" href="#actual_cost_tab"
                   role="tab" aria-selected="false" tabindex="-1"> ACTUAL COST </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" data-bs-toggle="tab" href="#preliminary_cost_tab"
                   role="tab" aria-selected="false" tabindex="-1"> PRELIMINARY COST
                </a>
              </li>
            </ul>
            <!-- TAB ENDS -->

            <!-- TAB CONTENT STARTS -->
            <div class="tab-content text-muted">
              <div class="tab-pane active" id="actual_cost_tab" role="tabpanel">
                <div class="table-responsive table-card">
                  <table class="table table-striped">
                    <thead>
                    <tr class="bg-light">
                      <th class="text-center">#</th>
                      <th class="text-center">Wagon</th>
                      <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">Agreed rate</th>
                      <th class="text-center">Additional Cost</th>
                      <th class="text-center py-0 m-0" v-for="party in order.counterparties"
                          :key="party"
                          @click="updateCounterparty(party)"
                      >
                        <span class="badge bg-success">{{ party.category.name }}</span>
                        <span class="d-block">{{ party.counterparty.name }}</span>
                      </th>
                      <th class="text-center">Total</th>
                      <th class="text-center">Total Expenses</th>
                      <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">Profit</th>
                    </tr>
                    <tr class="bg-white">
                      <th class="text-center"></th>
                      <th class="text-center">
                        <i v-b-tooltip.hover title="Copy Wagon Numbers"
                           @click="show_copy_wagon_numbers_modal = true"
                           class="mdi mdi-content-copy text-muted fs-5 align-middle me-2 cursor-pointer"></i>
                        <AddRow :order_id="order_id" @update="fetchData()"/>
                      </th>
                      <th v-if="!blacklisted_users_ids.includes(user.id)" class="text-center">
                        ${{
                          agreed_rate_sum.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        }}
                      </th>
                      <th class="text-center">
                        ${{
                          additional_cost_summ.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        }}
                      </th>
                      <th class="text-center" v-for="party in order.counterparties" :key="party">
                        ${{
                          parseFloat(party.total_expanses || 0).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        }}
                      </th>
                      <th class="text-center">
                        ${{
                          (expenses.reduce((a, b) => a + (parseFloat(b.agreed_rate || 0) + parseFloat(b.additional_cost || 0)), 0)).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        }}
                      </th>
                      <th class="text-center">
                        ${{
                          (total_expenses_sum || 0).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        }}
                      </th>
                      <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">
                        ${{
                          ((agreed_rate_sum + additional_cost_summ) - total_expenses_sum).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        }}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="(wagon, i) in expenses" :key="i"
                        @mouseover="delete_row_id=wagon.id"
                        @mouseleave="delete_row_id = null"
                    >
                      <th class="text-center">
                        <div class="btn-container me-3">
                          <Transition name="slide-up">
                            <span class="animation-item mx-2" v-if="delete_row_id !== wagon.id">{{ i + 1 }}</span>
                            <i v-else-if="delete_row_id === wagon.id"
                               @click="delete_wagon = wagon"
                               data-bs-toggle="modal" data-bs-target="#DeleteEmptyWagonModal"
                               class="mdi mdi-trash-can fs-20 animation-item align-top text-danger c_icon_hoverable"></i>
                          </Transition>
                        </div>
                      </th>
                      <td class="text-center" style="max-width: 150px">
                        <wagonInput :id="wagon.id" :wagon="wagon.wagon"
                                    :code="wagon.code === null ? '' : wagon.code"/>
                      </td>
                      <td v-if="!blacklisted_users_ids.includes(user.id)" class="text-center">
                        <agreedRate @update="fetchData()" :agreed_rate="wagon"/>
                      </td>
                      <td class="text-center">
                        <AdditionalCost
                            @update="fetchData()"
                            :additional_cost="wagon.additional_cost"
                            :id="wagon.id"
                        />
                      </td>
                      <td class="text-center" v-for="cost in wagon['actual_costs']" :key="cost.id"
                          style="max-width: 150px">
                        <actualCost @update="fetchData()" :act="cost.act" :actual_cost="cost"/>
                      </td>
                      <td class="text-center">
                        {{
                          (parseFloat(wagon.additional_cost || 0) + parseFloat(wagon.agreed_rate || 0)).toLocaleString(undefined, {
                            minimumFractionDigits: 2, maximumFractionDigits: 2
                          })
                        }}
                      </td>
                      <td class="text-center">
                        {{
                          (wagon.actual_costs.map(a => a.actual_cost).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)).toLocaleString(undefined, {
                            minimumFractionDigits: 2, maximumFractionDigits: 2
                          })
                        }}
                      </td>
                      <td class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">
                        {{
                          ((parseFloat(wagon.agreed_rate) +
                                  parseFloat(wagon.additional_cost
                                  )) -
                              wagon.actual_costs.map(a => a.actual_cost)
                                  .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                          ).toFixed(2)
                        }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane" id="preliminary_cost_tab" role="tabpanel">
                <div class="table-responsive table-card">
                  <table class="table table-striped">
                    <thead>
                    <tr class="bg-light">
                      <th class="text-center">#</th>
                      <th class="text-center">Wagon</th>
                      <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">Agreed rate
                      </th>
                      <th class="text-center">Additional Cost</th>
                      <th class="text-center py-0 m-0" v-for="party in order.counterparties"
                          :key="party"
                      >
                        <span class="badge bg-success">{{ party.category.name }}</span>
                        <span class="d-block">{{ party.counterparty.name }}</span>
                      </th>
                      <th class="text-center">Total</th>
                      <th class="text-center">Total Expenses</th>
                      <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">Profit</th>
                    </tr>
                    <tr class="bg-white">
                      <th class="text-center"></th>
                      <th class="text-center"></th>
                      <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">
                        ${{ agreed_rate_sum }}
                      </th>
                      <th class="text-center">
                        ${{ additional_cost_summ }}
                      </th>
                      <th class="text-center" v-for="party in order.counterparties" :key="party">
                        ${{ party.total_expanses }}
                      </th>
                      <th class="text-center">
                        ${{
                          (expenses.reduce((a, b) => a + (parseFloat(b.agreed_rate || 0) + parseFloat(b.additional_cost || 0)), 0)).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        }}
                      </th>
                      <th class="text-center">
                        ${{ total_expenses_sum }}
                      </th>
                      <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">
                        ${{ ((agreed_rate_sum + additional_cost_summ) - total_expenses_sum).toFixed(2) }}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(wagon, i) in expenses" :key="i">
                      <th class="text-center">{{ i + 1 }}</th>
                      <td class="text-center" style="max-width: 150px">
                        <input v-if="wagon.wagon === null"
                               class="form-control form-control-sm w-75 mx-auto" type="text"
                               readonly>
                        <input v-else-if="wagon.wagon !== null"
                               class="form-control form-control-sm w-75 mx-auto" type="text"
                               :value="wagon.wagon.name" readonly>
                      </td>
                      <td v-if="!blacklisted_users_ids.includes(user.id)" class="text-center">
                        <input class="form-control form-control-sm w-75 mx-auto" type="text"
                               :value="wagon.agreed_rate" readonly>
                      </td>
                      <td class="text-center">
                        <input class="form-control form-control-sm w-75 mx-auto" type="text"
                               :value="wagon.additional_cost" readonly>
                      </td>
                      <td class="text-center" v-for="cost in wagon_empty_preliminary_costs"
                          :key="cost.id"
                          style="max-width: 150px">
                        <input class="form-control form-control-sm w-75 mx-auto" type="text"
                               :value="cost.preliminary_cost" readonly>
                      </td>
                      <td class="text-center">
                        {{
                          (parseFloat(wagon.additional_cost || 0) + parseFloat(wagon.agreed_rate || 0)).toLocaleString(undefined, {
                            minimumFractionDigits: 2, maximumFractionDigits: 2
                          })
                        }}
                      </td>
                      <td class="text-center">
                        {{
                          (wagon_empty_preliminary_costs.map(a => a.preliminary_cost).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)).toLocaleString('en-US')
                        }}
                      </td>
                      <td class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">
                        {{
                          ((parseFloat(wagon.agreed_rate) +
                                  parseFloat(wagon.additional_cost
                                  )) -
                              wagon.actual_costs.map(a => a.actual_cost)
                                  .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                          ).toFixed(2)
                        }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- TAB CONTENT ENDS -->
          </div>
        </div>
      </div>
      <div class="col-xxl-3">
        <div class="card">
          <div class="card-header"><h5 class="card-title mb-0">Order Details</h5></div>
          <div class="card-body">
            <div class="table-responsive table-card">
              <table class="table table-borderless align-middle mb-0">
                <tbody>
                <tr>
                  <td class="fw-medium">Order number</td>
                  <td>{{ order.order_number }}</td>
                </tr>
                <tr>
                  <td class="fw-medium">Lot number</td>
                  <td>{{ order.lot_number === null ? '--' : order.lot_number.trim() }}</td>
                </tr>
                <tr>
                  <td class="fw-medium">Company</td>
                  <td>
                    <router-link v-if="order.company && order.company.slug" :to="{name: 'customer_profile', params: {
                      slug: order.company.slug
                    }}">
                      {{ order.company.name }}
                    </router-link>
                    <span v-else>{{ order.company ? order.company.name || '-' : '-' }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="fw-medium">Type</td>
                  <td>{{ order.type }}</td>
                </tr>
                <tr>
                  <td class="fw-medium">Departure</td>
                  <td>{{ order.departure.name }} ({{ order.departure.code }})</td>
                </tr>
                <tr>
                  <td class="fw-medium">Destination</td>
                  <td>{{ order.destination.name }} ({{ order.destination.code }})</td>
                </tr>
                <tr>
                  <td class="fw-medium">Shipment status</td>
                  <td class="text-capitalize">{{ order.shipment_status }}</td>
                </tr>
                <tr>
                  <td class="fw-medium">Payment status</td>
                  <td class="text-capitalize">{{ order.payment_status }}</td>
                </tr>
                <tr>
                  <td class="fw-medium">Date Created</td>
                  <td>{{ order.date }}</td>
                </tr>
                <tr>
                  <td class="fw-medium">Manager</td>
                  <td>
                    <router-link v-if="order.manager && order.manager.slug"
                                 :to="{name: 'user_profile', params: {slug: order.manager.slug}}"
                    >
                      {{ order.manager.username }}
                    </router-link>
                  </td>
                </tr>
                <tr>
                  <td class="fw-medium">Comment</td>
                  <td>
                    {{
                      order.comment === '' || order.comment === null ? '--' : order.comment.trim()
                    }}
                  </td>
                </tr>
                </tbody>
              </table>

              <button
                  class="btn btn-soft-success fw-medium w-100"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
              >See Actions
              </button>

            </div>
          </div>
        </div>

        <InvoiceList @invoiceDeleted="fetchData()"
                     :invoices_list="invoices_list"
                     :order_number="parseInt(order.order_number)"
        />

      </div>
    </div>

  </div>


  <CounterpartyActions
      v-if="!loading"
      :order_number="order.order_number"
      :counterparties="order.counterparties"
      :counterparty_list="counterparty_list"
      :category_list="category_list"
      @updateCounterparties="updatedCounterparties"
  />
  <WagonDelete @update="fetchData()"
               :wagon="delete_wagon">
  </WagonDelete>
</template>

<script>

import wagonInput from "./components/wagonInput.vue";
import agreedRate from "./components/agreedRate.vue";
import actualCost from "./components/actualCost.vue";
import WagonDelete from "./components/WagonDelete.vue";
import AdditionalCost from "./components/AdditionalCost.vue";
import CounterpartyActions from "./components/CounterpartyActions.vue";
import store from "@/state/store.js";
import OrdersApi from "@/api/orders/orders_api";
import skeleton from "@/components/custom/skeleton.vue";
import Swal from "sweetalert2";
import InvoiceList from "@/views/pages/orders/empty_wagon/components/InvoiceList.vue";
import axios from "axios";
import AddRow from "@/views/pages/orders/empty_wagon/modals/AddRow.vue";

export default {
  name: "empty_wagon_detail",
  data() {
    return {
      show_copy_wagon_numbers_modal: false,
      order_id: null,
      order: {},
      agreed_rate: 0,
      expenses: null,
      quantity: 0,
      wagon_empty_preliminary_costs: [],
      counterparty_list: [],
      category_list: [],
      invoices_list: [],
      loading: true,
      agreed_rate_sum: 0,
      total_expenses_sum: 0,
      blacklisted_users_ids: [],

      delete_row_id: null,
      delete_wagon: null,
    }
  },
  computed: {
    additional_cost_summ() {
      if (this.expenses) {
        return this.expenses
            .map(e => e.additional_cost || 0)
            .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      } else {
        return 0
      }
    },
    user() {
      return store.state.user
    }
  },
  components: {
    InvoiceList,
    wagonInput,
    agreedRate,
    actualCost,
    AdditionalCost,
    CounterpartyActions,
    skeleton,
    WagonDelete,
    AddRow
  },
  methods: {
    async fetchData() {
      try {
        let response = await axios.get(`/wagon_empty_order/list/${this.$route.params.id}/`)
        let data = await response.data[0]
        this.order_id = data['id']
        this.order = data['order']
        this.agreed_rate = data['agreed_rate'] || 0
        this.expenses = data['expanses'] || []
        this.quantity = data['quantity'] || 0
        this.wagon_empty_preliminary_costs = data['wagon_empty_preliminary_costs']
        this.invoices_list = data['order']['invoices'] || []

        this.getAgreedRateSum()
        this.getTotalExpensesSum()
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong',
        })
      }
    },
    async updatedCounterparties() {
      await this.fetchData();
      await this.getCategoryList()
      await this.getCounterpartyList()
    },
    async getCounterpartyList() {
      if (this.counterparty_list.length > 0) return
      let orders = new OrdersApi()
      this.counterparty_list = (await orders.getCounterpartyList()).results
    },
    async getCategoryList() {
      if (this.category_list.length > 0) return;
      let orders = new OrdersApi()
      this.category_list = (await orders.getCategoryList()).results
    },
    getAgreedRateSum() {
      try {
        this.agreed_rate_sum = this.expenses.map(s => s.agreed_rate || 0).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      } catch {
        this.agreed_rate_sum = 0
      }
    },
    getTotalExpensesSum() {
      try {
        this.total_expenses_sum = this.order.counterparties.map(s => s.total_expanses || 0).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      } catch {
        this.total_expenses_sum = 0
      }
    },
    async updateCounterparty(item) {
      const {value: formValues} = await Swal.fire({
        title: 'Update Counterparty',
        html:
            '<select class="form-select m-auto w-75 mt-3" id="categoryUpdate">' +
            `${this.category_list.map(c => {
              return item.category.id === c.id ? `<option value="${c.id}" selected>${c.name}</option>` : `<option value="${c.id}">${c.name}</option>`
            })}` +
            '</select>' +
            '<select class="form-select m-auto w-75 mt-3" id="counterpartyUpdate">' +
            `${this.counterparty_list.map(c => {
              return item.counterparty.id === c.id ? `<option value="${c.id}" selected>${c.name}</option>` : `<option value="${c.id}">${c.name}</option>`
            })}` +
            '</select>',
        focusConfirm: false,
        confirmButtonText: 'Save',
        confirmButtonColor: '#0AB39C',
        preConfirm: () => {
          return [
            document.getElementById('counterpartyUpdate').value,
            document.getElementById('categoryUpdate').value
          ]
        }
      })

      if (formValues) {
        let headers = new Headers();
        headers.append("Content-Type", `application/json`);

        let requestGetOptions = {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify({
            "category_id": formValues[1],
            "counterparty_id": formValues[0]
          }),
        };

        let response = await fetch(`${process.env.VUE_APP_ORDER_URL}/order/counterparty/update/${item.id}/`, requestGetOptions)
        if (response.status >= 200) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          await this.fetchData();

          await Toast.fire({
            icon: 'success',
            title: 'Successfully updated'
          })
        }
      }
    },
  },
  async mounted() {
    this.loading = true
    await this.fetchData()
    await this.getCounterpartyList()
    await this.getCategoryList()
    this.loading = false
  },
  watch: {
    '$route.params.id': function (newVal) {
      if (!newVal) return
      this.fetchData()
    }
  }
}
</script>


<style>
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}


.btn-container {
  display: inline-block;
  position: relative;
  height: 1em;
}

.animation-item {
  position: absolute;
}
</style>
